import React from "react";

import { CategoryToAncestorsCategoryConnection } from "interfaces/wordpress";
import CategoriesAccordionItem from "./categories-accordion-item";

const lexemes = {
  titleScreenReader: "Articles by Category",
  titleListScreenReader: "Articles",
  ctaTextScreenReader: "View more",
  ctaTextExtraScreenReader: " articles",
};

export type Props = {
  categoryTabs?: CategoryToAncestorsCategoryConnection;
};

export default function CategoriesAccordion(props: Props) {
  return (
    <>
      {/** Accordion-container - Mobile */}
      <div className="accordion-container">
        <h2 id="cat-heading" className="screen-reader-text">
          {lexemes.titleScreenReader}
        </h2>

        <ul
          id="catAccordion"
          className="accordion accordion-cards"
          aria-labelledby="cat-heading"
        >
          {props.categoryTabs?.edges?.map((edge, key) => (
            <React.Fragment key={key}>
              <CategoriesAccordionItem edge={edge} index={key} />
            </React.Fragment>
          ))}
        </ul>
      </div>
    </>
  );
}
