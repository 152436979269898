import React from "react";

import TopHeader from "components/the-buzz-theme/header/top-header";
import { Header } from "components/the-buzz-theme/header/header";
import { Footer } from "components/the-buzz-theme/footer/footer";
import { SVGList } from "components/the-buzz-theme/svg-list/svg-list";
import { Main } from "components/the-buzz-theme/main/main";

import { ResolvedType } from "interfaces";
import {
  RootQueryToPostConnection,
  CategoryToAncestorsCategoryConnection,
} from "interfaces/wordpress";
import { getRecentPosts } from "lib/api";
import Slider from "components/the-buzz-theme/slider/slider";
import Categories from "components/the-buzz-theme/categories/categories";
import PostFeed from "components/the-buzz-theme/post-feed/post-feed";
import ErrorBlock from "components/error-block/error-block";

import { Lexeme } from "@bumble/localization/components";

export type Props = {
  stickyPosts?: RootQueryToPostConnection;
  categoryTabs?: CategoryToAncestorsCategoryConnection;
  recentPosts?: ResolvedType<typeof getRecentPosts>;
  recentPostsTotal?: number;
  notFound?: boolean;
};

export default function MainPage(props: Props) {
  if (props.notFound) {
    return (
      <>
        <SVGList />
        <TopHeader />
        <Header />
        <Main>
          <ErrorBlock
            code={404}
            title={<Lexeme path="bma_client@video_chat.error_title" />}
            description={
              <>
                <p>
                  <Lexeme path="bma_client@pages.error.not-found.description" />
                </p>
                <p>
                  <a
                    className="btn btn-outline-primary"
                    href="/the-buzz?utm_source=bumble_buzz&utm_medium=not_found_page&utm_campaign=cta&utm_id=not-found_cta"
                  >
                    <Lexeme path="onboarding@bumble.incognito.dismiss_warning.cta.cancel" />
                  </a>
                </p>
              </>
            }
          />
        </Main>
        <Footer />
      </>
    );
  }
  return (
    <>
      <SVGList />
      <TopHeader />
      <Header />
      <Main>
        <Slider stickyPosts={props.stickyPosts} />
        <Categories categoryTabs={props.categoryTabs} />
        <PostFeed
          title="All Posts"
          posts={props.recentPosts}
          total={props.recentPostsTotal}
        />
      </Main>
      <Footer />
    </>
  );
}
