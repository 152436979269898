import React from "react";

type Props = {
  code?: React.ReactNode;
  title?: React.ReactNode;
  description?: React.ReactNode;
};

function ErrorBlock(props: Props) {
  const { code, title, description } = props;

  return (
    <div className="error-block">
      <div className="error-block__title">
        {code ? <div className="error-block__title-code">{code}</div> : null}
        {title ? <div className="error-block__title-text">{title}</div> : null}
      </div>
      {description ? (
        <div className="error-block__description">
          <div className="formatted-text">{description}</div>
        </div>
      ) : null}
    </div>
  );
}

export default ErrorBlock;
