import React from "react";

import { Post, RootQueryToPostConnection } from "interfaces/wordpress";
import SliderSlide from "components/the-buzz-theme/slider/slider-slide";
import { sliderOptions } from "./slider-options";
import Swiper from "swiper";

export type Props = {
  stickyPosts?: RootQueryToPostConnection;
  isMobileOnly?: boolean;
};

export default function Slider(props: Props) {
  React.useEffect(() => {
    if (!global.window.__swiperListDesktop) {
      global.window.__swiperListDesktop = [];
    }

    if (global.window.__swiperListDesktop.length < 1) {
      const swiper = new Swiper(
        ".swiper-desktop-container",
        sliderOptions.desktop as Swiper["params"],
      );
      global.window.__swiperListDesktop.push(swiper);
    }

    return () => {
      if (global.window.__swiperListDesktop) {
        global.window.__swiperListDesktop?.forEach((desktopSwiper: Swiper) =>
          desktopSwiper.destroy(),
        );
      }
    };
  }, []);

  React.useEffect(() => {
    if (!global.window.__swiperListMobile) {
      const swiper = new Swiper(
        ".swiper-mobile-container",
        sliderOptions.mobile,
      );
      global.window.__swiperListMobile = swiper;
    }
    return () => {
      global.window.__swiperListMobile.forEach((mobileSwiper: Swiper) =>
        mobileSwiper.destroy(),
      );
    };
  }, []);

  const mobileItems = props.stickyPosts?.nodes;

  const swiperMobile = (
    <div className="swiper-mobile">
      <div className="swiper-mobile-container post-loop-container">
        <div className="swiper-wrapper">
          {mobileItems &&
            mobileItems.map((stickyPost: Post, index) => (
              <React.Fragment key={stickyPost?.id || `slider-slider-${index}`}>
                <SliderSlide stickyPost={stickyPost || undefined} />
              </React.Fragment>
            ))}
        </div>
        {/* .swiper-wrapper  */}
      </div>
      {/* .swiper-mobile-container  */}
    </div>
  );
  if (props.isMobileOnly) {
    return swiperMobile;
  }
  const swiperDesktop = (
    <div className="swiper swiper-desktop">
      <div className="swiper-desktop-container swiper-container row post-loop-container">
        <div className="swiper-wrapper">
          {/* // Featured Post Loop  */}
          {props.stickyPosts?.nodes &&
            props.stickyPosts?.nodes.map((stickyPost: Post, index) => (
              <React.Fragment key={stickyPost?.id || `slider-slider-${index}`}>
                <SliderSlide stickyPost={stickyPost || undefined} />
              </React.Fragment>
            ))}
        </div>
        {/* .swiper-wrapper  */}
      </div>
      {/* .swiper-desktop-container row  */}
    </div>
  );
  const swiperWrapper = (
    <div id="featured-post" className="post-horizontal">
      <h2 className="screen-reader-text">Featured Articles for this Site</h2>
      <div className="container-xxl site-loop">
        {swiperMobile}

        {props.isMobileOnly ? null : swiperDesktop}

        <button type="button" className="swiper-button-next">
          <span className="screen-reader-text">Go to Next Slide</span>
        </button>
        <button type="button" className="swiper-button-prev">
          <span className="screen-reader-text">Go to Previous Slide</span>
        </button>
        <div className="swiper-pagination"></div>
      </div>
    </div>
  );
  return swiperWrapper;
}
