import React from "react";

import { CategoryToAncestorsCategoryConnection } from "interfaces/wordpress";
import CategoryTabsItem from "./categories-tabs-item";
import CategoryTabsNavigationItem from "./categories-tabs-navigation-item";

export type Props = {
  categoryTabs?: CategoryToAncestorsCategoryConnection;
};

export const CategoryTabs = (props: Props) => {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const handleClick = (index: number) => {
    setActiveIndex(index);
  };

  const categoryTabsNavigation = (
    <ul
      id="catTabs"
      className="nav nav-tabs"
      role="tablist"
      aria-labelledby="cat-heading"
    >
      {props.categoryTabs?.edges?.map((edge, navKey) => (
        <React.Fragment key={navKey}>
          <CategoryTabsNavigationItem
            edge={edge}
            navKey={navKey}
            activeIndex={activeIndex}
            onClick={() => handleClick(navKey)}
          />
        </React.Fragment>
      ))}
    </ul>
  );
  return (
    <div className="container-xxl tabs-container">
      {categoryTabsNavigation}
      <div id="nav-tabContent" className="tab-content site-loop">
        {props.categoryTabs?.edges?.map((edge, categoryKey) => (
          <React.Fragment key={categoryKey}>
            <CategoryTabsItem
              edge={edge}
              categoryKey={categoryKey}
              activeIndex={activeIndex}
            />
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default CategoryTabs;
