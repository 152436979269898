import React from "react";
import { animated, useSpring } from "@react-spring/web";

import {
  CategoryConnectionEdge,
  RootQueryToPostConnection,
} from "interfaces/wordpress";
import Slider from "components/the-buzz-theme/slider/slider";

const lexemes = {
  titleScreenReader: "Articles by Category",
  titleListScreenReader: "Articles",
  ctaTextScreenReader: "View more",
  ctaTextExtraScreenReader: " articles",
};

export type Props = {
  edge: CategoryConnectionEdge;
  index: number;
};

export default function CategoriesAccordionItem({ edge, index }: Props) {
  const id = index + 1;
  const node = edge?.node;

  const [isOpen, setIsOpen] = React.useState(false);
  const springProps = useSpring({
    height: isOpen ? "auto" : 0,
    config: {
      mass: 1,
      tension: 170,
      friction: 26,
    },
  });
  return (
    <li className="nav-item accordion-item" key={`accordion-item-${index}`}>
      <button
        className={`nav-link accordion-button ${isOpen === true ? "" : "collapsed"} ${index === 0 ? "active" : ""}`}
        type="button"
        data-bs-toggle="collapse"
        data-bs-target={`#cat-${id}-accordion`}
        aria-expanded={isOpen}
        aria-controls={`cat-${id}-accordion`}
        style={{
          backgroundColor: node.categoryPageFields?.categoryColor || undefined,
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <h3 className="tab-title" id={`${id}-accordion`}>
          {node?.name}
          <span className="screen-reader-text">
            {lexemes.titleListScreenReader}
          </span>
        </h3>
      </button>

      <animated.div
        style={springProps}
        className="tab-content accordion-collapse site-loop"
        id={`cat-${id}-accordion`}
        aria-labelledby={`${id}-accordion`}
      >
        <div
          className="accordion-body"
          style={{
            backgroundColor:
              node.categoryPageFields?.categoryColor || undefined,
          }}
        >
          <Slider
            stickyPosts={(node.posts as RootQueryToPostConnection) || undefined}
            isMobileOnly={true}
          />
          <div className="category-link">
            <a
              className="view-more-btn more-btn btn btn-outline-primary"
              role="button"
              href={`/the-buzz/${node.slug}`}
            >
              {lexemes.ctaTextScreenReader}
              <span className="screen-reader-text">{node.name}</span>
              {lexemes.ctaTextExtraScreenReader}
            </a>
          </div>
        </div>
      </animated.div>
    </li>
  );
}
